/////////////////////////// TEXT STUFF /////////////////////////////////////////
.title {
    font-size: 1.5em;
    margin-bottom: 1.5em;
}

.header {
    font-size: 1.2em;
}

.list {
    font-size: 0.9em;
    margin: 0.5em 0 1.5em 0;
}

li {
    font-size: 0.9em;
    margin-bottom: 0.5em;
}

a {
    color: #F8D831;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// CONTAINERS ///////////////////////////////////
.container {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    margin: 15vh 0;

    z-index: -5;
}

.row {
    display: flex;
    justify-content: space-between;
}

.row > :first-child {
    max-width: 35%;
}

.row > :last-child {
    max-width: 60%;
}

////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// 8-BIT BOX //////////////////////////////////
$border: 7px;
$corner: calc(-1 * $border / 2);

.box {
    background-color: rgba(43, 0, 255, 0.709);

    border-left: $border solid #2009a3;
    border-right: $border solid #2009a3;
    box-sizing: border-box;
    width: 100%;

    position: relative;
    display: inline-block;
    
    color: #ffffff;
    text-align: left;
}

.wrapper {
    box-sizing: border-box;
    height: calc(100% + 2 * $border);

    padding: 20px;
    margin: calc($border * -1) 0;
    border-top: $border solid #2009a3;
    border-bottom: $border solid #2009a3;

    display: flex;
    flex-direction: column;

    white-space: normal;
}

.box::before,
.box::after,
.wrapper::before,
.wrapper::after{
    content: '';
    width: $border;
    height: $border;
    position: absolute;
    background: #2009a3;
}
.box::before{ top: $corner; left: $corner; }
.box::after{ top: $corner; right: $corner; }
.wrapper::before{ bottom: $corner; left: $corner; }
.wrapper::after{ bottom: $corner; right: $corner; }

////////////////////////////////////////////////////////////////////////////////
///////////////////////// PROF PIC TITLE CONTAINER /////////////////////////////

.profpic {
    max-width: 100px;
    height: auto;
    clip-path: circle();
    float: left;
}

.ppcontainer {
    display: flex;
    align-items: center;
}