.container {
    width: 100%;
    padding: 15vh 0;
}

.project {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5vh 0;
}

.photo {
    max-width: 30%;
    height: auto;
}

.description {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.heading {
    font-size: 1.5em;
    padding: 0.3em;
}

.text {
    padding: 0.3em;
    color: white;
}