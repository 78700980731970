@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@400..900&display=swap");

body {
  margin: 0 5vw;
  font-family: "Gabarito", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4bb3e2;
  max-width: 100%;
  overflow-x: hidden;
  line-height: 2em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
