.container {
    position: fixed;
    top: 0;
    right: 5px;
    width: max-content;

    z-index: 5;
}

.menu {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 5px;
    color: white;
    background-color: #00c700b5;
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    min-height: 160px;

    background: url("./../photos/vine.png") no-repeat center center fixed; 
    background-size: 60px;

    position: relative;
    top: -160px;

    z-index: -3;
}
