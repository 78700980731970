.wrapper {
  margin-top: 10vh;
  min-height: 100vh;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  padding: 10vh 0;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 50px;

  color: white;
  text-shadow: 0 0 5px #000000;
}

.title {
  font-size: 4.75em;
  line-height: 1.3em;
  // text-shadow: -5px -5px #000000;
}

.content {
  font-size: 1.5em;
}

.links {
  display: flex;
  gap: 10px;
}
